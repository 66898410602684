.chart-summary {
    .ant-row {
        border: 1px solid #dedede;
    }
    .ant-col {
        padding: 8px;
    }

    .ant-col {
        border-right: 1px solid #dedede;
    }
    .ant-col:last-child {
        border-right: none;
    }
}
