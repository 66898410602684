.login-page-v2 {
    position: relative;
    min-height: 100vh;
    overflow-x: hidden;
    background: #e3effb;
}

.login-page-v2__left {
    padding: 5rem 7rem;
    @media (max-width: 767px) {
        padding: 2rem 1rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

.login-page-v2__left__subtitle {
    color: #004578 !important;
    font-size: 32px !important;
    @media (max-width: 767px) {
        font-size: 16px !important;
    }
}

.login-page-v2__right {
    padding: 4rem 2rem;
    background: #fff;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (min-width: 768px) {
        min-height: 100vh;
    }
    @media (max-width: 767px) {
        padding: 2rem 1rem;
        border-bottom-left-radius: 0;
        border-top-right-radius: 2rem;
    }
}

.login-page-v2__right__logo {
    width: 60px;
    margin: auto auto 2rem;

    @media (max-width: 767px) {
        width: 120px;
        margin: auto auto 0;
    }
}

.login-page-v2__right__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    color: #333 !important;
}

.login-page-v2__right__header__name {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0 !important;
}

.login-page-v2__right__header__text {
    font-size: 16px;
}

.login-page-v2__right__form {
    width: 70%;
    max-width: 500px;
    @media (max-width: 767px) {
        width: 100%;
    }
}

.divider-between-text {
    color: #e4e4e4;
}

.login-page-v2__background-video {
    position: absolute;
    bottom: 0;
    left: -3px;
    width: 53%;
    z-index: 0;
}

.color-new-primary {
    color: #0078d4 !important;
}

.color-black {
    color: #333 !important;
}

.login-page-v2 .ant-form-item-label {
    padding-bottom: 2px !important;
}
